import config from "../config/config";
import request from "./request";

async function query(
    id,
    statusArray,
    chainTypeArray,
    claimTypeArray,
    userId,
    toAddress,
    signId,
    signHex,
    startDate,
    endDate,
    limit,
    offset,
    fromDb,
    token
) {
    let url = config.api.endpoint + "/api/simple_claim/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                status: statusArray,
                claim_type: claimTypeArray,
                user_id: userId,
                chain_type: chainTypeArray,
                to_address: toAddress,
                sign_id: signId,
                sign_hex: signHex,
                start_date: startDate,
                end_date: endDate,
            },
            limit: limit,
            offset: offset,
            from_db: fromDb,
        },
        token
    );
}

async function importList(targetList, token) {
    let url = config.api.endpoint + "/api/simple_claim/import";
    return await request.Post(
        url,
        {
            target_list: targetList,
        },
        token
    );
}

export default {
    query,
    importList,
};
