import config from "../config/config";
import request from "./request";

async function query(id,user_id,from_user_email,from_user_name,from_phone_number,statusArray,startDate,endDate,limit,offset, token) {
    let url = config.api.endpoint + "/api/old_msntt_transfer/query";
    return await request.Post(
        url,
        {
            filter:{
                id:id,
                user_id:user_id,
                from_user_email:from_user_email,
                from_user_name:from_user_name,
                from_phone_number:from_phone_number,
                status:statusArray,
                start_date:startDate,
                end_date:endDate,
            },
            limit:limit,
            offset:offset,
        },
        token
    );
}

async function apply(from_user_email,email_vcode,from_user_name,from_phone_number,remark,token) {
    let url = config.api.endpoint + "/api/old_msntt_transfer/apply";
    return await request.Post(
        url,
        {
            from_user_email:from_user_email,
            email_vcode:email_vcode,
            from_user_name:from_user_name,
            from_phone_number:from_phone_number,
            remark:remark
        },
        token
    );
}

async function cancel(idArray,token) {
    let url = config.api.endpoint + "/api/old_msntt_transfer/cancel";
    return await request.Post(
        url,
        {
            id:idArray,
        },
        token
    );
}

async function reject(idArray,token) {
    let url = config.api.endpoint + "/api/old_msntt_transfer/reject";
    return await request.Post(
        url,
        {
            id:idArray,
        },
        token
    );
}

async function approve(idArray,token) {
    let url = config.api.endpoint + "/api/old_msntt_transfer/approve";
    return await request.Post(
        url,
        {
            id:idArray
        },
        token
    );
}

async function manual_finish(idArray,token){
    let url = config.api.endpoint + "/api/old_msntt_transfer/manual_finish";
    return await request.Post(
        url,
        {
            id:idArray
        },
        token
    );
}



export default {
    query,
    apply,
    cancel,
    reject,
    approve,
    manual_finish,
};