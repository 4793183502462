function parserToMoneyFormat(amount,decimals,toFixed=6,forceFix=false){
    if(amount==="0"||amount===""){
        return "0.00"
    }

    if(decimals>=amount.length){
        let round=decimals-amount.length+1
        for(let i=0;i<round;i++){
            amount="0"+amount
        }
    }


    let arr=amount.split("")
    arr.splice(-decimals, 0, ".")

    if(decimals>toFixed){
        let round=decimals-toFixed
        for(let i=0;i<round;i++){
            if (arr[arr.length-1]!=='0'&&!forceFix){
                break
            }
            arr.splice(arr.length-1, 1 )
        }
    }
    return arr.join("")
}

function parseDepositMsg(msg) {
    if (msg === "") {
        return "";
    } else {
        let resp = msg.split(':');
        if (resp.length > 0) {
            return resp[1];
        } else {
            return resp[0];
        }
    }
}

export default {
    parserToMoneyFormat,
    parseDepositMsg
}