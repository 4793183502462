import config from "../config/config";
import request from "./request";

async function createPullZone(originUrl,tag, token) {
    let url = config.api.endpoint + "/api/pullzone/create";
    return await request.Post(
        url,
        {
            origin: originUrl,
            tag:tag,
        },
        token
    );
}

async function queryPullZone(id, origin, origin_pattern, name,forbidden,userId, limit, offset, token) {
    let url = config.api.endpoint + "/api/pullzone/query";
    return await request.Post(
        url,
    {
            filter: {
                id: id,
                origin:origin,
                origin_pattern: origin_pattern,
                name: name,
                forbidden:forbidden,
                userid: userId,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}


async function updatePullZone(id,name,tag,forbidden,token) {
    let url = config.api.endpoint + "/api/pullzone/update";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
        update:{
            name:name,
            tag:tag,
            forbidden:forbidden,
        },
    }, token);
}

async function deletePullZone(id,token) {
    let url = config.api.endpoint + "/api/pullzone/delete";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
    }, token);
}

async function activate(id,token) {
    let url = config.api.endpoint + "/api/pullzone/activate";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
    }, token);
}

// async function forbidden(id,token) {
//     let url = config.api.endpoint + "/api/pullzone/forbidden";
//     return await request.Post(url, {
//         filter:{
//             id:[id],
//         },
//     }, token);
// }

async function purge(id,token) {
    let url = config.api.endpoint + "/api/pullzone/purge";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
    }, token);
}

async function queryTraffic(userId,pullZoneId,continentCode,countryCode,startDate,endDate,token) {
    let url = config.api.endpoint + "/api/pullzone/traffic_query";
    return await request.Post(url, {
        filter:{
            user_id:userId,
            pull_zone_id:pullZoneId,
            continent_code:continentCode,
            country_code:countryCode,
            start_date:startDate,
            end_date:endDate
        },
    }, token);
}

async function queryPdnUsage(userId,pullZoneId,startDate,endDate,token) {
    let url = config.api.endpoint + "/api/pullzone/pdn_query";
    return await request.Post(url, {
        filter:{
            user_id:userId,
            pull_zone_id:pullZoneId,
            start_date:startDate,
            end_date:endDate
        },
    }, token);
}

export default {
    createPullZone,
    queryPullZone,
    updatePullZone,
    deletePullZone,
    activate,
    // forbidden,
    // purge,
    queryTraffic,
    queryPdnUsage,
};
