import user from "./user"
import captcha from "./captcha"
import pullzone from "./pullzone"
import domain from "./domain"
import price from "./price"
import kv from "./kv"
import scoreRule from "./score_geo_rule"
import node from "./node"
import speedTest from "./speed_test"
import claim from "./claim"
import simpleClaim from './simple_claim'
import info from './info'
import oldMsnttTransfer from './old_msntt_transfer'
import nodeFlag from './node_flag'
import agentNode from './agent_node'
import mcloud from "./mcloud";
import releasedReward from "./released_reward"
import deposit from "./deposit"

export default {
    user,
    captcha,
    pullzone,
    domain,
    price,
    kv,
    scoreRule,
    node,
    speedTest,
    claim,
    simpleClaim,
    info,
    oldMsnttTransfer,
    nodeFlag,
    agentNode,
    mcloud,
    releasedReward,
    deposit,
}