import config from "../config/config";
import request from "./request";

async function getTokenInfo() {
    let url = config.api.endpoint + "/api/info/token_info";
    return await request.Get(
        url, 
    );
}

export default {
    getTokenInfo
};