import config from "../config/config"
import request from "./request"
import {off} from "v-calendar/src/utils";

async function queryCloudList(token, limit, offset) {
    let url = config.api.mesoncloud+ "/cloud?" + "limit=" + limit + "&offset=" + offset;
    return await request.Get(url, token);
}

async function createCloud(token, image, cloudType, zone, cnt, tag) {
    let url = config.api.mesoncloud+ "/cloud";
    return await request.Post(url, {
        image: image,
        cloudType: cloudType,
        zone: zone,
        count: cnt,
        tag: tag,
    }, token);
}

async function queryTotalRows(token) {
    let url = config.api.mesoncloud+ "/cloud/total";
    return await request.Get(url, token);
}

async function queryDefaultGateway(token) {
    let url = config.api.mesoncloud+ "/gatewayx/default";
    return await request.Get(url, token);
}

async function deleteCloud(id, token) {
    let url = config.api.mesoncloud + "/cloud/" + id;
    return await request.Delete(url, token);
}

async function updateCloud(id, tag, token) {
    let url = config.api.mesoncloud + "/cloud/" + id;
    return await request.Patch(url, {
        tag: tag,
    }, token);
}

async function setDefaultGatewayStatus(id, status, token) {
    let url = config.api.mesoncloud + "/gatewayx/default/" + id;
    return await request.Patch(url, {
        status: status,
    }, token);
}

export default {
    queryCloudList,
    createCloud,
    queryTotalRows,
    queryDefaultGateway,
    deleteCloud,
    updateCloud,
    setDefaultGatewayStatus,
}