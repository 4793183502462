import config from "../config/config";
import request from "./request";

async function queryNode(ipArray,userId,continentCode,countryCode,limit,offset, token) {
    let url = config.api.endpoint + "/api/node/query";
    return await request.Post(
        url,
        {
            filter:{
                node_ip:ipArray,
                user_id:userId,
                continent_code:continentCode,
                country_code:countryCode
            },
            limit:limit,
            offset:offset,
        },
        token
    );
}

async function queryUserNodeCount(userIdArray,token){
    let url = config.api.endpoint + "/api/node/user_count";
    return await request.Post(
        url,
        {
            user_id:userIdArray
        },
        token
    );
}

async function queryNodeCount(continentCodeArray,countryCodeArray) {
    let url = config.api.endpoint + "/api/node/query_count";
    return await request.Post(
        url,
        {
            continent_code:continentCodeArray,
            country_code:countryCodeArray
        },
    );
}

async function queryGeoBandwidth(continentCodeArray,countryCodeArray) {
    let url = config.api.endpoint + "/api/node/query_bandwidth";
    return await request.Post(
        url,
        {
            continent_code:continentCodeArray,
            country_code:countryCodeArray
        },
    );
}

async function version(){
    let url = config.api.endpoint + "/api/node/version";
    return await request.Get(url);
}

async function modifyIpLocation(ipArray,countryCode,token){
    let url = config.api.endpoint + "/api/node/custom_ip_location/set";
    return await request.Post(
        url,
        {
            ip:ipArray,
            country_code:countryCode,
        },
        token
    );
}


export default {
    queryNode,
    queryNodeCount,
    queryGeoBandwidth,
    version,
    queryUserNodeCount,
    modifyIpLocation,
};