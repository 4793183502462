const passwordReg = /(?=.*([a-zA-Z].*))(?=.*[0-9].*)[a-zA-Z0-9-*/+.~!@#$%^&*(){}|_=]{6,20}$/; //must contain number and letter length 6-20
const emailReg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const referrerReg = /^(?=^.{3,255}$)[a-zA-Z0-9*][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;

const customDomainReg =/^(?=^.{3,255}$)[a-zA-Z0-9][-a-zA-Z0-9]{0,62}(\.[a-zA-Z0-9][-a-zA-Z0-9]{0,62})+$/;
const integerReg=/^\+?[1-9]\d*$/

export function validatePassword(password) {
    return password.length>=6&&password.length<=20
}

export function validateEmail(email) {
    return emailReg.test(email);
}

export function validateReferrer(referrer) {
    return referrerReg.test(referrer);
}

export function validateCustomDomain(domain){
    return customDomainReg.test(domain);
}

export function validateInteger(value){
    return integerReg.test(value);
}

export default {
    validatePassword,
    validateEmail,
    validateReferrer,
    validateCustomDomain,
    validateInteger,
}
