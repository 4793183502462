import config from "../config/config";
import request from "./request";

async function speedTestList(continentCode,limit,offset, token) {
    let url = config.api.endpoint + "/api/speed_tester/test_list";
    return await request.Post(
        url,
        {
            filter:{
                continent_code:continentCode,
            },
            limit:limit,
            offset:offset,
        },
        token
    );
}

async function speedTestCmd(ipArray, token) {
    let url = config.api.endpoint + "/api/speed_tester/test_cmd";
    return await request.Post(
        url,
        {
            ips:ipArray
        },
        token
    );
}


export default {
    speedTestList,
    speedTestCmd,
};