import config from "../config/config";
import request from "./request";

async function adminDeposit(userId, creditType, amount, log, token) {
    let url = config.api.endpoint + "/api/deposit/admin_mint";
    return await request.Post(
        url,
        {
            user_id: userId,
            credit_type: creditType,
            amount: amount,
            log: log,
        },
        token
    );
}

async function queryDeposit(id, userId, refUserId, creditType, tag, operatorId, paymentType, paymentId, startDate, endDate, limit, offset, token) {
    let url = config.api.endpoint + "/api/deposit/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                user_id: userId,
                reference_user_id: refUserId,
                credit_type: creditType,
                tag: tag,
                operator_id: operatorId,
                payment_type: paymentType,
                payment_id: paymentId,
                start_date: startDate,
                end_date: endDate,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function cryptomus_query(id, order_id, cryptomus_id, user_id, status, start_date, end_date, limit, offset, token) {
    let url = config.api.endpoint + "/api/cryptomus/query";
    return await request.Post(
      url,
      {
        filter: {
          id: id,
          order_id: order_id,
          cryptomus_id: cryptomus_id,
          user_id: user_id,
          status: status,
          start_date: start_date,
          end_date: end_date,
        },
        limit: limit,
        offset: offset,
      },
      token
    );
  }

export default {
    adminDeposit,
    queryDeposit,

    cryptomus_query,
};
