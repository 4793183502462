import config from "../config/config";
import request from "./request";

async function preAddDomain(domain, pullZoneId, token){
    let url = config.api.endpoint + "/api/domain/preadd_domain";
    return await request.Post(
        url,
        {
            domain: domain,
            pull_zone_id: pullZoneId,
        },
        token
    );
}

async function verifyCname(domain, pullZoneId, token){
    let url = config.api.endpoint + "/api/domain/verify_cname";
    return await request.Post(
        url,
        {
            domain: domain,
            pull_zone_id: pullZoneId,
        },
        token
    );
}

async function verifyChallenge(domain, pullZoneId, autoCert, token){
    let url = config.api.endpoint + "/api/domain/verify_challenge";
    return await request.Post(
        url,
        {
            domain: domain,
            pull_zone_id: pullZoneId,
            auto_cert: autoCert,
        },
        token
    );
}

async function verifyCertificate(domain, pullZoneId, autoCert, cert, key, token){
    let url = config.api.endpoint + "/api/domain/verify_cert";
    return await request.Post(
        url,
        {
            domain: domain,
            pull_zone_id: pullZoneId,
            auto_cert: autoCert,
            cert: cert,
            key: key,
        },
        token
    );
}

async function createDomain(domain, pullZoneId, autoCert, cert, key, token) {
    let url = config.api.endpoint + "/api/domain/create";
    return await request.Post(
        url,
        {
            domain: domain,
            pull_zone_id: pullZoneId,
            auto_cert: autoCert,
            cert: cert,
            key: key,
            sys_default: false,
        },
        token
    );
}

async function queryDomain(id, domain, domainPattern, pullZoneId, lessThanExpirationUnixTime, autoCert,sysDefault, limit, offset, token) {
    let url = config.api.endpoint + "/api/domain/query";
    return await request.Post(
        url,
        {
            filter: {
                dd: id,
                domain: domain,
                domain_pattern: domainPattern,
                pull_zone_id: pullZoneId,
                less_than_expiration_unixtime: lessThanExpirationUnixTime,
                auto_cert: autoCert,
                sys_default:sysDefault,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function deleteDomain(id,token) {
    let url = config.api.endpoint + "/api/domain/delete";
    return await request.Post(url, {
        filter:{
            id:[id],
        }
    }, token);
}

async function updateDomain(id,autoCert,cert,key,token) {
    let url = config.api.endpoint + "/api/domain/update";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
        update:{
            auto_cert: autoCert,
            cert: cert,
            key: key,
        },
    }, token);
}

export default {
    preAddDomain,
    verifyCname,
    verifyChallenge,
    verifyCertificate,
    createDomain,
    queryDomain,
    deleteDomain,
    updateDomain,
};
