import config from "../config/config";
import request from "./request";

async function createPrice(continentCode,countryCode,pdnPerByte,userId, token) {
    let url = config.api.endpoint + "/api/price/create";
    return await request.Post(
        url,
        {
            continent_code:continentCode,
            country_code:countryCode,
            pdn_per_byte:pdnPerByte,
            user_id:userId,
        },
        token
    );
}

async function queryPrice(id,userId,continentCode,countryCode,fromDb, limit, offset, token) {
    let url = config.api.endpoint + "/api/price/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                user_id:userId,
                continent_code:continentCode,
                country_code:countryCode,
                from_db:fromDb,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function deletePrice(id,token) {
    let url = config.api.endpoint + "/api/price/delete";
    return await request.Post(url, {
        filter:{
            id:[id],
        }
    }, token);
}

async function updatePrice(id,continentCode,countryCode,pdnPerByte,token) {
    let url = config.api.endpoint + "/api/price/update";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
        update:{
            continent_code:continentCode,
            country_code:countryCode,
            pdn_per_byte:pdnPerByte,
        },
    }, token);
}


export default {
    createPrice,
    queryPrice,
    deletePrice,
    updatePrice
};