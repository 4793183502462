import config from "../config/config";
import request from "./request";

async function createScoreRule(continentCode,countryCode,rate, token) {
    let url = config.api.endpoint + "/api/score_geo_rule/create";
    return await request.Post(
        url,
        {
            continent_code:continentCode,
            country_code:countryCode,
            rate:rate,
        },
        token
    );
}

async function queryScoreRule(id,continentCode,countryCode, limit, offset, token) {
    let url = config.api.endpoint + "/api/score_geo_rule/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                continent_code:continentCode,
                country_code:countryCode,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function deleteScoreRule(id,token) {
    let url = config.api.endpoint + "/api/score_geo_rule/delete";
    return await request.Post(url, {
        filter:{
            id:[id],
        }
    }, token);
}

async function updateScoreRule(id,rate,token) {
    let url = config.api.endpoint + "/api/score_geo_rule/update";
    return await request.Post(url, {
        filter:{
            id:[id],
        },
        update:{
            rate:rate,
        },
    }, token);
}


export default {
    createScoreRule,
    queryScoreRule,
    deleteScoreRule,
    updateScoreRule
};