import config from "../config/config";
import request from "./request";

async function totalReleased(creditType, token) {
    let url = config.api.endpoint + "/api/user/meson_reward/total_released";
    return await request.Post(
        url,
        {
            credit_type: creditType,
        },
        token
    );
}

async function dailyReleased(creditType, startDate, endDate, token) {
    let url = config.api.endpoint + "/api/user/meson_reward/daily_released";
    return await request.Post(
        url,
        {
            credit_type: creditType,
            start_date: startDate,
            end_date: endDate,
        },
        token
    );
}

export default {
    totalReleased,
    dailyReleased,
};
