import config from "../config/config";
import request from "./request";

async function getAuthConfig() {
    let url = config.api.endpoint + "/api/user/auth_config";
    return await request.Get(url);
}

async function login(email, password, captchaId, captcha) {
    let url = config.api.endpoint + "/api/user/login";
    return await request.Post(url, {
        email: email,
        password: password,
        captcha_id: captchaId,
        captcha: captcha,
    });
}

async function getUserInfo(token) {
    let url = config.api.endpoint + "/api/user/info";
    return await request.Get(url, token);
}

async function resetPassword(email, password, vcode) {
    let url = config.api.endpoint + "/api/user/reset_password";
    return await request.Post(url, {
        email: email,
        password: password,
        vcode: vcode,
    });
}

async function getEmailVCode(email) {
    let url = config.api.endpoint + "/api/user/email_vcode";
    return await request.Post(url, {
        email: email,
    });
}

async function register(email, password, captchaId, captcha, vcode) {
    let url = config.api.endpoint + "/api/user/register";
    return await request.Post(url, {
        email: email,
        password: password,
        vcode: vcode,
        captcha_id: captchaId,
        captcha: captcha,
    });
}

async function deleteUser(id, token) {
    let url = config.api.endpoint + "/api/user/delete";
    return await request.Post(
        url,
        {
            filter: {
                id: [id],
            },
        },
        token
    );
}

async function updateUser(id, forbidden, roles, permissions, pullZoneLimit, token) {
    let url = config.api.endpoint + "/api/user/update";
    return await request.Post(
        url,
        {
            filter: {
                id: [id],
            },
            update: {
                forbidden: forbidden,
                roles: roles,
                permissions: permissions,
                pull_zone_limit: pullZoneLimit,
            },
        },
        token
    );
}

async function queryUser(userId, emailPattern, userToken, forbidden, creditUserId, limit, offset, token) {
    let url = config.api.endpoint + "/api/user/query";
    return await request.Post(
        url,
        {
            filter: {
                id: userId,
                email_pattern: emailPattern,
                token: userToken,
                forbidden: forbidden,
                credit_user_id: creditUserId,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

// async function userWallet(token) {
//     let url = config.api.endpoint + "/api/user/wallet";
//     return await request.Get(url, token);
// }

async function userMesonCreditBalance(idArray, creditType, token) {
    let url = config.api.endpoint + "/api/user/meson_credit/balance";
    return await request.Post(
        url,
        {
            filter: {
                id: idArray,
                credit_type: creditType,
            },
        },
        token
    );
}

async function userMesonRewardBalance(idArray, creditType, token) {
    let url = config.api.endpoint + "/api/user/meson_reward/balance";
    return await request.Post(
        url,
        {
            filter: {
                id: idArray,
                credit_type: creditType,
            },
        },
        token
    );
}

async function queryMesonCreditDepositRecord(fromUserId, toUserId, tag, startDate, endDate, limit, offset, token) {
    let url = config.api.endpoint + "/api/user/meson_credit/deposit_record";
    return await request.Post(
        url,
        {
            filter: {
                from_user_id: fromUserId,
                to_user_id: toUserId,
                tag: tag,
                start_date: startDate,
                end_date: endDate,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function adminMesonCreditDeposit(userId, amount, token) {
    let url = config.api.endpoint + "/api/user/meson_credit/admin_deposit";
    return await request.Post(
        url,
        {
            user_id: userId,
            amount: amount,
        },
        token
    );
}

async function queryMesonRewardExtraChangeRecord(id, UserId, creditType, tag, startDate, endDate, limit, offset, token) {
    let url = config.api.endpoint + "/api/user/meson_reward/extra_change_record";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                user_id: UserId,
                credit_type: creditType,
                tag: tag,
                start_date: startDate,
                end_date: endDate,
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function adminModifyMesonReward(userId, amount, creditType, allow_negative, tag, log, token) {
    let url = config.api.endpoint + "/api/user/meson_reward/admin_modify";
    return await request.Post(
        url,
        {
            user_id: userId,
            amount: amount,
            credit_type: creditType,
            allow_negative: allow_negative,
            tag: tag,
            log: log,
        },
        token
    );
}

async function queryMiningRecord(userId, credit_type, startDate, endDate, token) {
    let url = config.api.endpoint + "/api/user/mining_record";
    return await request.Post(
        url,
        {
            filter: {
                user_id: userId,
                credit_type: credit_type,
                start_date: startDate,
                end_date: endDate,
            },
        },
        token
    );
}

async function getEstimatedReward(userId, creditType, token) {
    let url = config.api.endpoint + "/api/user/estimate_reward";
    return await request.Post(
        url,
        {
            user_id: userId,
            credit_type: creditType,
        },
        token
    );
}

// Payment
// async function stripeCheckout(mode, productName, amount, token) {
//     let url = config.api.mesoncloud + "/payment/stripe_checkout";
//     return await request.Post(url, {
//         mode: mode,
//         product_name: productName,
//         amount: amount,
//     }, token);
// }

// async function coinbaseCheckout(mode, productName, amount, token) {
//     let url = config.api.mesoncloud + "/payment/coinbase_checkout";
//     return await request.Post(url, {
//         mode: mode,
//         product_name: productName,
//         amount: amount,
//     }, token);
// }

// async function getUserMesonCreditBalance(token) {
//     let url = config.api.mesoncloud + "/payment/balance" + "?credit_type=MESON_CREDIT";
//     return await request.Get(url, token);
// }

// async function queryMesonCreditDepositRecord(creditType, startDate, endDate, limit, offset, token) {
//     let url = config.api.mesoncloud + "/payment/deposit/history" + "?credit_type=" + creditType + "&start_date=" +
//         startDate + "&end_date=" + endDate + "&limit=" + limit + "&offset=" + offset;
//     return await request.Get(url, token);
// }

export default {
    getAuthConfig,
    login,
    getUserInfo,
    register,
    updateUser,
    queryUser,
    deleteUser,
    getEmailVCode,
    resetPassword,
    // userWallet,
    userMesonCreditBalance,
    userMesonRewardBalance,
    queryMesonCreditDepositRecord,
    adminMesonCreditDeposit,
    queryMesonRewardExtraChangeRecord,
    adminModifyMesonReward,
    queryMiningRecord,
    getEstimatedReward,
    // stripeCheckout,
    // coinbaseCheckout,
    // getUserMesonCreditBalance,
    // queryMesonCreditDepositRecord,
};
