
export function ipToTag(ip)  {
    let result = ['x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x', 'x']
    for (let i = 0; i < ip.length; i++) {
        switch (ip[i]) {
            case '.':
                result[i]='k'
                break;
        
            default:
                result[i]=String.fromCharCode(ip[i].charCodeAt()+49)
                break;
        }
    }
    return result.join('')
}

export default {
    ipToTag
}