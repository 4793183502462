//compare version (x.x.x)
//
//a>b return 1
//
//a==b return 0
//

//a<b  return -1
export function versionCompare(a , b )  {
	let aVersion = a.split(".")
	let bVersion = b.split(".")
	for (let i=0;i<aVersion.length;i++) {
		let a=parseInt(aVersion[i])
		let b=parseInt(bVersion[i])
		if (a > b) {
			return 1
		} else if (a < b) {
			return -1
		}
	}
	return 0
}

export default {
    versionCompare
}