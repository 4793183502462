import config from "../config/config";
import request from "./request";

async function addAgentNode(userId,countryCode,bandwidth_Mbps_min,bandwidth_Mbps_max,expireTime,stor_total_GB,cpu, op_sys,count,token) {
    let url = config.api.endpoint + "/api/node/agent_node/add";
    return await request.Post(
        url,
        {
            user_id:userId,
            country_code:countryCode,
            bandwidth_Mbps_min:bandwidth_Mbps_min,
            bandwidth_Mbps_max:bandwidth_Mbps_max,
            expire_unixtime:expireTime,
            stor_total_GB:stor_total_GB,
            cpu:cpu,
            op_sys:op_sys,
            count:count,
        },
        token
    );
}

async function queryAgentNode(id,userId,ip,beforeExpireTime,country_code,continent_code,status, limit, offset, token) {
    let url = config.api.endpoint + "/api/node/agent_node/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                user_id:userId,
                ip:ip,
                less_than_expire_unixtime:beforeExpireTime,
                country_code:country_code,
                continent_code:continent_code,
                status:status
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function deleteAgentNode(idArray,token) {
    let url = config.api.endpoint + "/api/node/agent_node/remove";
    return await request.Post(url, {
        id:idArray,
    }, token);
}

async function updateAgentNode(idArray,bandwidth_Mbps_min,bandwidth_Mbps_max,expireTime,stor_total_GB,cpu,op_sys,token) {
    let url = config.api.endpoint + "/api/node/agent_node/update";
    return await request.Post(url, {
        filter:{
            id:idArray,
        },
        update:{
            bandwidth_Mbps_min:bandwidth_Mbps_min,
            bandwidth_Mbps_max:bandwidth_Mbps_max,
            expire_unixtime:expireTime,
            stor_total_GB:stor_total_GB,
            cpu:cpu,
            op_sys:op_sys,
        },
    }, token);
}


export default {
    addAgentNode,
    queryAgentNode,
    deleteAgentNode,
    updateAgentNode
};