import config from "../config/config";
import request from "./request";

async function query(id, chainTypeArray, statusArray, userId, creditType, toAddress, signId, signHex, startDate, endDate, limit, offset, fromDb, token) {
    let url = config.api.endpoint + "/api/claim/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                chain_type: chainTypeArray,
                status: statusArray,
                user_id: userId,
                credit_type: creditType,
                to_address: toAddress,
                sign_id: signId,
                sign_hex: signHex,
                start_date: startDate,
                end_date: endDate,
            },
            limit: limit,
            offset: offset,
            from_db: fromDb,
        },
        token
    );
}

export default {
    query,
};
