import config from "../config/config";
import request from "./request";

async function createNodeFlag(userId,ip,expireTime, token) {
    let url = config.api.endpoint + "/api/node/agent_flag/add";
    return await request.Post(
        url,
        {
            user_id:userId,
            ip:[ip],
            expire_unixtime:expireTime
        },
        token
    );
}

async function queryNodeFlag(id,userId,ip,beforeExpireTime, limit, offset, token) {
    let url = config.api.endpoint + "/api/node/agent_flag/query";
    return await request.Post(
        url,
        {
            filter: {
                id: id,
                user_id:userId,
                ip:ip,
                less_than_expire_unixtime:beforeExpireTime
            },
            limit: limit,
            offset: offset,
        },
        token
    );
}

async function deleteNodeFlag(idArray,token) {
    let url = config.api.endpoint + "/api/node/agent_flag/remove";
    return await request.Post(url, {
        
            id:idArray,
       
    }, token);
}

async function updateNodeFlag(idArray,expireTime,token) {
    let url = config.api.endpoint + "/api/node/agent_flag/update";
    return await request.Post(url, {
        filter:{
            id:idArray,
        },
        update:{
            expire_unixtime:expireTime,
        },
    }, token);
}


export default {
    createNodeFlag,
    queryNodeFlag,
    deleteNodeFlag,
    updateNodeFlag
};