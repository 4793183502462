export default [
    {
        header: "Pages",
        items: [
            {
                name: "Welcome",
                icon: "BoxIcon",
                menu_hidden: true,
                path: "/",
                component: () => import("../pages/example/welcome.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin;
                    },
                },
            },
        ],
    },
    {
        header: "ADMIN",
        items: [
            {
                icon: "UsersIcon",
                name: "User Manager",
                path: "/admin/user_manager",
                component: () => import("../pages/user_mgr/user_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"));
                    },
                },
            },
            {
                icon: "MapIcon",
                name: "Score Geo Rule",
                path: "/admin/score_geo_rule_manager",
                component: () => import("../pages/score_geo_rule_mgr/score_geo_rule_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"));
                    },
                },
            },
            {
                icon: "CloudIcon",
                name: "Pullzone Manager",
                path: "/admin/pullzone_manager",
                component: () => import("../pages/pullzone_mgr/pullzone_list.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"));
                    },
                },
            },
            {
                icon: "Share2Icon",
                name: "Nodes Manager",
                path: "/admin/node_manager",
                component: () => import("../pages/node_mgr/node_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"));
                    },
                },
            },
            {
                icon: "ThermometerIcon",
                name: "SpeedTest List",
                path: "/admin/speed_test_list",
                component: () => import("../pages/speed_test_list/speed_test_list.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"));
                    },
                },
            },
            {
                icon: "DollarSignIcon",
                name: "Meson Credit Deposit",
                path: "/admin/admin_meson_credit_deposit_manager",
                component: () => import("../pages/admin_meson_credit_deposit_mgr/admin_meson_credit_deposit_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "DollarSignIcon",
                name: "Cryptomus record",
                path: "/admin/admin_cryptomus_record_mgr",
                component: () => import("../pages/admin_cryptomus_record_mgr/admin_cryptomus_record_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "DropletIcon",
                name: "MesonReward Modify",
                path: "/admin/admin_modify_msnn_manager",
                component: () => import("../pages/admin_modify_msnn_mgr/admin_modify_msnn_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "ExternalLinkIcon",
                name: "Claim Manager",
                path: "/admin/admin_claim_manager",
                component: () => import("../pages/admin_claim_mgr/admin_claim_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "LogInIcon",
                name: "Simple Claim Manager",
                path: "/admin/admin_simple_claim_manager",
                component: () => import("../pages/admin_simple_claim_mgr/admin_simple_claim_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "DollarSignIcon",
                name: "Released reward",
                path: "/admin/released_reward",
                component: () => import("../pages/reward_released/reward_released.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "DatabaseIcon",
                name: "Kv Manager",
                path: "/admin/kv_manager",
                component: () => import("../pages/kv_mgr/kv_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && (roles.includes("admin") || roles.includes("read_only"));
                    },
                },
            },
            {
                name: "old msntt transfer",
                path: "/old_msntt_transfer_mgr",
                icon: "DollarSignIcon",
                component: () => import("../pages/old_msntt_transfer_mgr/old_msntt_transfer_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },

            {
                icon: "LogInIcon",
                name: "Flag Node Manager",
                path: "/admin/a_node_flag_manager",
                component: () => import("../pages/a_node_flag_mgr/a_node_flag_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            {
                icon: "Share2Icon",
                name: "AN Manager",
                path: "/admin/a_node_manager",
                component: () => import("../pages/a_node_mgr/a_node_mgr.vue"),
                meta: {
                    layout: "vertical",
                    auth: (islogin, roles, permissions) => {
                        return islogin && roles.includes("admin");
                    },
                },
            },
            // {
            //     name: "api reference",
            //     icon: "BookOpenIcon",
            //     path: "https://api.meson.network/index.html",
            //     meta: {
            //         layout: "vertical",
            //         auth: (islogin, roles, permissions) => {
            //             return islogin && roles.includes("admin");
            //         },
            //     },
            // },
        ],
    },
];
